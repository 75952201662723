import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import AddCommProcForm from "../page/add/AddCommProcForm";
import {
  collection,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../fbserve";

const ProcessForm = () => {
  let dataRef: any;
  const commDatStr = "pages/commissions/process";
  const commProcRef = query(collection(db, commDatStr));
  const qproc = query(commProcRef, orderBy("order", "asc"));
  // add Comm Process Refs
  const addCommProcFormRef = useRef<HTMLFormElement>(null);
  const addCommProcOrderRef = useRef<HTMLInputElement>(null);
  const addCommProcTextRef = useRef<HTMLTextAreaElement>(null);
  const addCommProcTitleRef = useRef<HTMLInputElement>(null);
  const proctitleFormRef = useRef<HTMLFormElement>(null);
  const proctitleRef = useRef<HTMLInputElement>(null);
  const proctextFormRef = useRef<HTMLFormElement>(null);
  const proctextRef = useRef<HTMLTextAreaElement>(null);
  const procorderFormRef = useRef<HTMLFormElement>(null);
  const procorderRef = useRef<HTMLInputElement>(null);
  const proccategoryFormRef = useRef<HTMLFormElement>(null);
  const proccategoryRef = useRef<HTMLInputElement>(null);

  const [processes, setProcesses] = useState([] as Array<any> | null);
  const [editProc, setEditProc] = useState(false);
  const [proc, setProc] = useState(false);
  const [procIndex, setProcIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const getProcesses = async () => {
    const commProc = [] as Array<any>;
    const querySnapshot = await getDocs(qproc);
    querySnapshot.forEach((doc: any) => {
      commProc?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setProcesses(commProc);
  };

  useEffect(() => {
    getProcesses();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const procEditProc = (i: number) => {
    const nextState = i;
    setEditProc(true);
    setProcIndex(nextState);
  };

  const addCommProc = async (ev: any) => {
    ev.preventDefault();
    const word: any = addCommProcTitleRef.current?.value.split(" ");
    const pgId: string = word[1].toLowerCase();
    const iOrder = addCommProcOrderRef.current?.value as string;
    await setDoc(doc(db, "pages/commissions/process", pgId), {
      category: "process",
      order: parseInt(iOrder),
      text: addCommProcTextRef.current?.value,
      title: addCommProcTitleRef.current?.value,
    })
      .then(() => {
        addCommProcFormRef.current!.reset();
        getProcesses();
      })
      .catch((error) => console.log(error.message));
  };

  const delPageSec = async (ev: any, id: string, deldir: string) => {
    ev.preventDefault();
    let confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${processes?.[procIndex]?.title} Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, deldir, `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        setEditProc(false);
        getProcesses();
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const runProcEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (
      inptName === "order" ||
      inptName === "heightpx" ||
      inptName === "widthpx" ||
      inptName === "widththumb"
    ) {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getProcesses();
  };

  return (
    <div>
      {proc === true ? (
        <div className="editdiv">
          {editProc ? (
            <div>
              <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
                {deleted ? (
                  <span style={{ color: "red" }}>Deleted!</span>
                ) : (
                  "Editing:"
                )}{" "}
                Commissions Process - {processes?.[procIndex]?.title}
              </h4>
              <div>
                <EditForm
                  fRef={proccategoryFormRef}
                  iRef={proccategoryRef}
                  fname="category"
                  ftype="text"
                  aIndexName={processes?.[procIndex]?.category}
                  onSubmit={(ev) =>
                    runProcEdit(
                      ev,
                      processes?.[procIndex]?.id,
                      proccategoryFormRef,
                      proccategoryRef,
                      commDatStr
                    )
                  }
                />
                <EditForm
                  fRef={procorderFormRef}
                  iRef={procorderRef}
                  fname="order"
                  ftype="text"
                  aIndexName={processes?.[procIndex]?.order}
                  onSubmit={(ev) =>
                    runProcEdit(
                      ev,
                      processes?.[procIndex]?.id,
                      procorderFormRef,
                      procorderRef,
                      commDatStr
                    )
                  }
                />
                <EditForm
                  fRef={proctextFormRef}
                  tRef={proctextRef}
                  fname="text"
                  ftype="text"
                  aIndexName={processes?.[procIndex]?.text}
                  onSubmit={(ev) =>
                    runProcEdit(
                      ev,
                      processes?.[procIndex]?.id,
                      proctextFormRef,
                      proctextRef,
                      commDatStr
                    )
                  }
                />
                <EditForm
                  fRef={proctitleFormRef}
                  iRef={proctitleRef}
                  fname="title"
                  ftype="text"
                  aIndexName={processes?.[procIndex]?.title}
                  onSubmit={(ev) =>
                    runProcEdit(
                      ev,
                      processes?.[procIndex]?.id,
                      proctitleFormRef,
                      proctitleRef,
                      commDatStr
                    )
                  }
                />
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <p>ID: {processes?.[procIndex]?.id}</p>
                  <button
                    className="btn"
                    onClick={(ev) =>
                      delPageSec(ev, processes?.[procIndex]?.id, commDatStr)
                    }
                  >
                    Delete
                  </button>
                </div>
                <button className="btn" onClick={() => setEditProc(false)}>
                  Close
                </button>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingBottom: "1rem",
            }}
          >
            <div style={{ width: "100%" }}>
              <h4>Process:</h4>
            </div>
            {processes?.map((process: any, i: number) => (
              <button
                key={process.title}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  procEditProc(i);
                }}
              >
                {process.title}
              </button>
            ))}
          </div>
          <div>
            <AddCommProcForm
              onSubmit={(ev) => {
                addCommProc(ev);
              }}
              fRef={addCommProcFormRef}
              iORef={addCommProcOrderRef}
              iHRef={addCommProcTextRef}
              iTRef={addCommProcTitleRef}
            />
          </div>
          <button className="btn" onClick={() => setProc(false)}>
            Close Comm Processes
          </button>
        </div>
      ) : (
        <button className="btn" onClick={() => setProc(true)}>
          Edit Comm Processes
        </button>
      )}
    </div>
  );
};

export default ProcessForm;
