import { type FormEventHandler, type RefObject } from "react";

type AddFormProps = {
	fRef: RefObject<HTMLFormElement>;
	iTRef?: RefObject<HTMLTextAreaElement>;
	iORef?: RefObject<HTMLInputElement>;
	onSubmit: FormEventHandler;
};
const AddPublicationsForm = ({
	fRef,
	iTRef,
	iORef,
	onSubmit,
}: AddFormProps) => (
	<form
		className=""
		ref={fRef}
		autoComplete="off"
		onSubmit={onSubmit}
		name="add"
	>
		<fieldset className="formfield">
			<label htmlFor="pubtext">text: </label>
			<textarea
				style={{ marginLeft: "0.5rem" }}
				id="pubtext"
				ref={iTRef}
				name="text"
				rows={5}
				required
			/>
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addpuborder">order: </label>
			<input id="addpuborder" ref={iORef} type="text" name="order" required />
		</fieldset>
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				marginBottom: "1rem",
			}}
		>
			<button type="submit" className="btn valuebtn">
				Add Publication
			</button>
		</div>
	</form>
);

export default AddPublicationsForm;
