import SignInForm from "./forms/start/SignInForm";

const App = () => {
  return (
    <div className="App">
      <h3 style={{ textAlign: "center" }}>Edit johnansenartist.com:</h3>
      <section>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a
            href="https://console.firebase.google.com/u/0/project/jha-web/overview"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 1rem" }}
          >
            JHA-WEB Firebase Project
          </a>
          <a
            href="https://johnhansenartist.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 1rem" }}
          >
            Johnhansenartist.com
          </a>
        </div>
        <SignInForm />
      </section>
    </div>
  );
};

export default App;
