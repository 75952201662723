import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import AddStepsForm from "../page/add/AddStepsForm";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  collection,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../fbserve";

const StepsForm = () => {
  let dataRef: any;
  const techImgStr = "pages/technique/steps";
  const techStepsRef = query(collection(db, techImgStr));
  const qsteps = query(techStepsRef, orderBy("order", "asc"));
  // add Technique Steps Refs
  const techCategoryFormRef = useRef<HTMLFormElement>(null);
  const techCategoryRef = useRef<HTMLInputElement>(null);
  const techUploadFormRef = useRef<HTMLFormElement>(null);
  const techUploadRef = useRef<HTMLInputElement>(null);
  const techColourFormRef = useRef<HTMLFormElement>(null);
  const techColourRef = useRef<HTMLInputElement>(null);
  const techFormatsFormRef = useRef<HTMLFormElement>(null);
  const techFormatsRef = useRef<HTMLSelectElement>(null);
  const techHeightpxFormRef = useRef<HTMLFormElement>(null);
  const techHeightpxRef = useRef<HTMLInputElement>(null);
  const techImgaltFormRef = useRef<HTMLFormElement>(null);
  const techImgaltRef = useRef<HTMLInputElement>(null);
  const techImgqualityFormRef = useRef<HTMLFormElement>(null);
  const techImgqualityRef = useRef<HTMLSelectElement>(null);
  const techImgsrcFormRef = useRef<HTMLFormElement>(null);
  const techImgsrcRef = useRef<HTMLInputElement>(null);
  const techOrderFormRef = useRef<HTMLFormElement>(null);
  const techOrderRef = useRef<HTMLInputElement>(null);
  const techStepFormRef = useRef<HTMLFormElement>(null);
  const techStepRef = useRef<HTMLInputElement>(null);
  const techTextFormRef = useRef<HTMLFormElement>(null);
  const techTextRef = useRef<HTMLTextAreaElement>(null);
  const techTitleFormRef = useRef<HTMLFormElement>(null);
  const techTitleRef = useRef<HTMLInputElement>(null);
  const techWidthpxFormRef = useRef<HTMLFormElement>(null);
  const techWidthpxRef = useRef<HTMLInputElement>(null);
  const techWidththumbFormRef = useRef<HTMLFormElement>(null);
  const techWidththumbRef = useRef<HTMLInputElement>(null);
  const addTechStepFormRef = useRef<HTMLFormElement>(null);
  const addTechStepTitleRef = useRef<HTMLInputElement>(null);
  const addTechStepRef = useRef<HTMLInputElement>(null);
  const addTechStepOrderRef = useRef<HTMLInputElement>(null);

  const [steps, setSteps] = useState([] as Array<any> | null);
  const [editStep, setEditStep] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [showStepsList, setShowStepsList] = useState(false);
  const [showAddStep, setShowAddStep] = useState(false);

  const getTechSteps = async () => {
    const techSteps = [] as Array<any>;
    const querySnapshot = await getDocs(qsteps);
    querySnapshot.forEach((doc: any) => {
      techSteps?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setSteps(techSteps);
  };

  useEffect(() => {
    getTechSteps();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const setEditStepClose = () => {
    setEditStep(false);
    setRedEdited(false);
  };

  const pageEditStep = (i: number) => {
    const nextState = i;
    setEditStep(true);
    setShowAddStep(false);
    setStepIndex(nextState);
  };

  const startAddStep = () => {
    setShowAddStep((prev) => !prev);
  };

  const startStepsList = () => {
    setShowStepsList((prev) => !prev);
    setShowAddStep(false);
  };

  const addTechStep = async (ev: any) => {
    ev.preventDefault();
    const word: any = addTechStepRef.current?.value.split(" ");
    const pgId: string = word[0].toLowerCase();
    const iOrder = addTechStepOrderRef.current?.value as string;
    await setDoc(doc(db, techImgStr, pgId), {
      category: "step",
      colour: "hsl(50, 50%, 50%)",
      formats: ["avif", "webp"],
      imgquality: "max",
      order: parseInt(iOrder),
      step: addTechStepRef.current?.value,
      title: addTechStepTitleRef.current?.value,
      widthpx: 729,
      widththumb: 450,
    })
      .then(() => {
        addTechStepFormRef.current!.reset();
        getTechSteps();
      })
      .catch((error) => console.log(error.message));
  };

  const delPageSec = async (ev: any, id: string, deldir: string) => {
    ev.preventDefault();
    let confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${steps?.[stepIndex]?.step} Step Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, deldir, `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        getTechSteps();
        pageEditStep(0);
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const runStepEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (
      inptName === "order" ||
      inptName === "heightpx" ||
      inptName === "widthpx" ||
      inptName === "widththumb"
    ) {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getTechSteps();
  };

  const storage = getStorage();
  let urlArr: Array<any> = [];
  const uploadImg = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.files;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (inptVal.length < 1 && inptVal.length > 1) {
      alert("Please Select Only One Image File");
      setRedEdited(false);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      return;
    } else if (inptVal.length === 1) {
      for (let i: number = 0; i < inptVal!.length; i++) {
        const artimagesRef = ref(storage, `art/${inptVal![i].name}`);
        await uploadBytes(artimagesRef, inptVal![i]);
        await getDownloadURL(artimagesRef)
          .then((url) => {
            urlArr.push(url);
          })
          .catch((error: any) => {
            console.log("Error Getting Img URLs!", error.message);
          });
      }
      dataRef = doc(db, dataStr, id);
      await updateDoc(dataRef, { [`${inptName}`]: urlArr[0].toString() });
      setRedEdited(true);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      getTechSteps();
    }
  };

  return (
    <div>
      {editStep ? (
        <div className="editdiv">
          <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
            {deleted ? (
              <span style={{ color: "red" }}>Deleted!</span>
            ) : (
              "Editing:"
            )}{" "}
            Technique Step - {steps?.[stepIndex]?.step}
          </h4>
          <div>
            <EditForm
              fRef={techUploadFormRef}
              iRef={techUploadRef}
              fname="imgsrc"
              ftype="file"
              detfull=""
              aIndexName={steps?.[stepIndex]?.imgsrc}
              onSubmit={(ev) =>
                uploadImg(
                  ev,
                  steps?.[stepIndex]?.id,
                  techUploadFormRef,
                  techUploadRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techCategoryFormRef}
              iRef={techCategoryRef}
              fname="category"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.category}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techCategoryFormRef,
                  techCategoryRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techColourFormRef}
              iRef={techColourRef}
              fname="colour"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.colour}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techColourFormRef,
                  techColourRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techFormatsFormRef}
              sRef={techFormatsRef}
              fname="formats"
              iselect={true}
              detfull=""
              isize={2}
              ioptions={["avif", "webp", "png", "jpg", "gif", "svg"]}
              aIndexName={steps?.[stepIndex]?.formats!}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techFormatsFormRef,
                  techFormatsRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techHeightpxFormRef}
              iRef={techHeightpxRef}
              fname="heightpx"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.heightpx}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techHeightpxFormRef,
                  techHeightpxRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techImgaltFormRef}
              iRef={techImgaltRef}
              fname="imgalt"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.imgalt}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techImgaltFormRef,
                  techImgaltRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techImgqualityFormRef}
              sRef={techImgqualityRef}
              fname="imgquality"
              detfull=""
              iselect={true}
              isize={1}
              ioptions={["max", "high", "mid", "low"]}
              aIndexName={steps?.[stepIndex]?.imgquality}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techImgqualityFormRef,
                  techImgqualityRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techImgsrcFormRef}
              iRef={techImgsrcRef}
              fname="imgsrc"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.imgsrc}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techImgsrcFormRef,
                  techImgsrcRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techOrderFormRef}
              iRef={techOrderRef}
              fname="order"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.order}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techOrderFormRef,
                  techOrderRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techStepFormRef}
              iRef={techStepRef}
              fname="step"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.step}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techStepFormRef,
                  techStepRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techTextFormRef}
              tRef={techTextRef}
              fname="text"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.text}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techTextFormRef,
                  techTextRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techTitleFormRef}
              iRef={techTitleRef}
              fname="title"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.title}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techTitleFormRef,
                  techTitleRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techWidthpxFormRef}
              iRef={techWidthpxRef}
              fname="widthpx"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.widthpx}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techWidthpxFormRef,
                  techWidthpxRef,
                  techImgStr
                )
              }
            />
            <EditForm
              fRef={techWidththumbFormRef}
              iRef={techWidththumbRef}
              fname="widththumb"
              ftype="text"
              detfull=""
              aIndexName={steps?.[stepIndex]?.widththumb}
              onSubmit={(ev) =>
                runStepEdit(
                  ev,
                  steps?.[stepIndex]?.id,
                  techWidththumbFormRef,
                  techWidththumbRef,
                  techImgStr
                )
              }
            />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <p>ID: {steps?.[stepIndex]?.id}</p>
              <button
                className="btn"
                onClick={(ev) =>
                  delPageSec(ev, steps?.[stepIndex]?.id, techImgStr)
                }
              >
                Delete
              </button>
            </div>
            <button className="btn" onClick={setEditStepClose}>
              Close
            </button>
          </div>
        </div>
      ) : null}
      {showAddStep ? (
        <AddStepsForm
          onSubmit={(ev) => {
            addTechStep(ev);
          }}
          fRef={addTechStepFormRef}
          iTRef={addTechStepTitleRef}
          iSRef={addTechStepRef}
          iORef={addTechStepOrderRef}
        />
      ) : null}
      <button className="btn btnheading" onClick={startStepsList}>
        {showStepsList ? `Hide Steps` : `Show Steps`}
      </button>
      {showStepsList ? (
        <div>
          <div
            style={{
              borderTop: "1px solid white",
              paddingTop: "1rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {steps?.map((step: any, i: number) => (
              <button
                key={step.step}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  pageEditStep(i);
                }}
              >
                {step.step} {step.order}
              </button>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              borderBottom: "1px solid white",
              marginBottom: "1rem",
            }}
          >
            <button className="btn" onClick={startAddStep}>
              {showAddStep ? `Hide Add Steps` : `Add Steps`}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StepsForm;
