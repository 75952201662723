import { useState, useEffect, useRef } from "react";
import { slugify } from "../../utilities/slugify";
import { db } from "../../fbserve";
import type { Artwork } from "../../lib/myTypes";
import {
  collection,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import EditForm from "../template/EditForm";
import AddArtForm from "./add/AddArtForm";

// **********Edit ARTWORKS Component*******
const EditArtworks = () => {
  const artworksRef = collection(db, "artwork");
  const q = query(artworksRef, orderBy("order", "desc"));

  const [art, setArtworks] = useState([] as Array<any> | null);
  const [showArtList, setShowArtList] = useState(false);
  const [showAddArt, setShowAddArt] = useState(false);
  const [editArt, setEditArt] = useState(false);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [artIndex, setArtIndex] = useState(0);

  // edit artwork Ref values
  const artistFormRef = useRef<HTMLFormElement>(null);
  const artistRef = useRef<HTMLInputElement>(null);
  const availableFormRef = useRef<HTMLFormElement>(null);
  const availableRef = useRef<HTMLInputElement>(null);
  const categoryFormRef = useRef<HTMLFormElement>(null);
  const categoryRef = useRef<HTMLSelectElement>(null);
  const heightFormRef = useRef<HTMLFormElement>(null);
  const heightRef = useRef<HTMLInputElement>(null);
  const homeimgFormRef = useRef<HTMLFormElement>(null);
  const homeimgRef = useRef<HTMLInputElement>(null);
  const detailUploadFormRef = useRef<HTMLFormElement>(null);
  const detailUploadRef = useRef<HTMLInputElement>(null);
  const detailColourFormRef = useRef<HTMLFormElement>(null);
  const detailColourRef = useRef<HTMLInputElement>(null);
  const detailFormatsFormRef = useRef<HTMLFormElement>(null);
  const detailFormatsRef = useRef<HTMLSelectElement>(null);
  const detailHeightpxFormRef = useRef<HTMLFormElement>(null);
  const detailHeightpxRef = useRef<HTMLInputElement>(null);
  const detailImgaltFormRef = useRef<HTMLFormElement>(null);
  const detailImgaltRef = useRef<HTMLInputElement>(null);
  const detailImgqualityFormRef = useRef<HTMLFormElement>(null);
  const detailImgqualityRef = useRef<HTMLSelectElement>(null);
  const detailImgsrcFormRef = useRef<HTMLFormElement>(null);
  const detailImgsrcRef = useRef<HTMLInputElement>(null);
  const detailWidthpxFormRef = useRef<HTMLFormElement>(null);
  const detailWidthpxRef = useRef<HTMLInputElement>(null);
  const detailWidththumbFormRef = useRef<HTMLFormElement>(null);
  const detailWidththumbRef = useRef<HTMLInputElement>(null);
  const fullUploadFormRef = useRef<HTMLFormElement>(null);
  const fullUploadRef = useRef<HTMLInputElement>(null);
  const fullColourFormRef = useRef<HTMLFormElement>(null);
  const fullColourRef = useRef<HTMLInputElement>(null);
  const fullFormatsFormRef = useRef<HTMLFormElement>(null);
  const fullFormatsRef = useRef<HTMLSelectElement>(null);
  const fullHeightpxFormRef = useRef<HTMLFormElement>(null);
  const fullHeightpxRef = useRef<HTMLInputElement>(null);
  const fullImgaltFormRef = useRef<HTMLFormElement>(null);
  const fullImgaltRef = useRef<HTMLInputElement>(null);
  const fullImgqualityFormRef = useRef<HTMLFormElement>(null);
  const fullImgqualityRef = useRef<HTMLSelectElement>(null);
  const fullImgsrcFormRef = useRef<HTMLFormElement>(null);
  const fullImgsrcRef = useRef<HTMLInputElement>(null);
  const fullWidthpxFormRef = useRef<HTMLFormElement>(null);
  const fullWidthpxRef = useRef<HTMLInputElement>(null);
  const fullWidththumbFormRef = useRef<HTMLFormElement>(null);
  const fullWidththumbRef = useRef<HTMLInputElement>(null);
  const includeFormRef = useRef<HTMLFormElement>(null);
  const includeRef = useRef<HTMLInputElement>(null);
  const keyidFormRef = useRef<HTMLFormElement>(null);
  const keyidRef = useRef<HTMLInputElement>(null);
  const lochFormRef = useRef<HTMLFormElement>(null);
  const lochRef = useRef<HTMLInputElement>(null);
  const mediumFormRef = useRef<HTMLFormElement>(null);
  const mediumRef = useRef<HTMLSelectElement>(null);
  const metaFormRef = useRef<HTMLFormElement>(null);
  const metaRef = useRef<HTMLInputElement>(null);
  const orderFormRef = useRef<HTMLFormElement>(null);
  const orderRef = useRef<HTMLInputElement>(null);
  const priceFormRef = useRef<HTMLFormElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const slugFormRef = useRef<HTMLFormElement>(null);
  const slugRef = useRef<HTMLInputElement>(null);
  const subjectFormRef = useRef<HTMLFormElement>(null);
  const subjectRef = useRef<HTMLSelectElement>(null);
  const textFormRef = useRef<HTMLFormElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const titleFormRef = useRef<HTMLFormElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const typeFormRef = useRef<HTMLFormElement>(null);
  const typeRef = useRef<HTMLSelectElement>(null);
  const widthFormRef = useRef<HTMLFormElement>(null);
  const widthRef = useRef<HTMLInputElement>(null);
  const yearFormRef = useRef<HTMLFormElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  //add form Ref values
  const addFormRef = useRef<HTMLFormElement>(null);
  const addTitleRef = useRef<HTMLInputElement>(null);
  const addKeyidRef = useRef<HTMLInputElement>(null);
  const addOrderRef = useRef<HTMLInputElement>(null);

  const getArtworks = async () => {
    const artworks = [] as Array<any>;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      artworks?.push({ ...doc.data(), id: doc.id });
    }) as unknown as Artwork;
    setArtworks(artworks);
  };

  useEffect(() => {
    getArtworks();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const setEditArtClose = () => {
    setEditArt(false);
    setRedEdited(false);
  };

  const artEditCard = (i: number) => {
    const nextState = i;
    setEditArt(true);
    setArtIndex(nextState);
  };

  const startArtList = () => {
    setShowArtList((prev) => !prev);
    setShowAddArt(false);
  };

  const startAddArt = () => {
    setShowAddArt((prev) => !prev);
    setShowArtList(false);
  };

  const addArtwork = (ev: any) => {
    ev.preventDefault();
    const iOrder = addOrderRef.current?.value as string;
    addDoc(artworksRef, {
      artist: "John Hansen",
      available: true,
      category: "Artwork",
      homeimg: false,
      title: addTitleRef.current?.value,
      include: false,
      keyid: addKeyidRef.current?.value,
      loch: "https://lochgallery.com/artist/john-hansen",
      medium: "oil on Panel",
      subject: "still life",
      order: parseInt(iOrder),
      slug: slugify(addTitleRef!.current!.value.toString()),
      type: "painting",
      imgdetail: {
        formats: ["avif", "webp"],
        widthpx: 900,
        widththumb: 450,
        imgquality: "max",
        colour: "hsl(50, 50%, 50%)",
      },
      imgfull: {
        formats: ["avif", "webp"],
        widthpx: 900,
        widththumb: 450,
        imgquality: "max",
        colour: "hsl(50, 50%, 50%)",
      },
    })
      .then(() => {
        addFormRef.current!.reset();
        getArtworks();
      })
      .catch((error) => console.log(error.message));
  };

  const delArtwork = async (id: string) => {
    let confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete ${art?.[artIndex]?.title} Artwork Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      deleteDoc(doc(db, "artwork", `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        getArtworks();
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const storage = getStorage();
  let urlArr: Array<any> = [];
  const uploadImg = async (ev: any, id: string, refForm: any, refInpt: any) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.files;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    const artRef = doc(db, "artwork", id);
    if (inptVal.length < 1 && inptVal.length > 1) {
      alert("Please Select Only One Image File");
      setRedEdited(false);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      return;
    } else if (inptVal.length === 1) {
      for (let i: number = 0; i < inptVal!.length; i++) {
        const artimagesRef = ref(storage, `art/${inptVal![i].name}`);
        await uploadBytes(artimagesRef, inptVal![i]);
        await getDownloadURL(artimagesRef)
          .then((url) => {
            if (url as unknown as string) {
              urlArr.push(url);
            }
          })
          .catch((error: any) => {
            console.log("Error Getting Img URLs!", error.message);
          });
      }
      await updateDoc(artRef, { [`${inptName}`]: urlArr[0].toString() });
      setRedEdited(true);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      await getArtworks();
    }
  };

  const runEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    iTitle: string
  ) => {
    ev.preventDefault();
    setRedEdited((prev) => !prev);
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (
      inptName === "order" ||
      inptName.includes("heightpx") ||
      inptName.includes("widthpx") ||
      inptName.includes("widththumb")
    ) {
      inptVal = parseInt(inptVal);
    }
    if (
      inptName === "available" ||
      inptName === "include" ||
      inptName === "homeimg"
    ) {
      inptVal = refInpt.current?.checked ? true : false;
    }
    if (inptName.includes("formats")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    if (inptName === "artist") {
      inptVal = "John Hansen";
    }
    if (inptName === "loch") {
      inptVal = "https://lochgallery.com/artist/john-hansen";
    }
    if (inptName === "slug") {
      inptVal = slugify(iTitle);
    }
    const artRef = doc(db, "artwork", id);
    if (inptName === "title") {
      getArtworks();
      await updateDoc(artRef, { slug: slugify(inptVal) });
      setRedEdited(true);
      slugFormRef!.current!.className = `${
        redEdited ? "editform red" : "editform"
      }`;
    }
    await updateDoc(artRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    refForm.current.className = `${redEdited ? "editform red" : "editform"}`;
    refForm.current!.reset();
    getArtworks();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          borderBottom: "1px solid white",
          marginBottom: "1rem",
        }}
      >
        <button className="btn btnheading" onClick={startArtList}>
          {showArtList ? `Hide Artworks` : `Show Artworks`}
        </button>
      </div>
      {editArt ? (
        <div className="editdiv">
          <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
            {deleted ? (
              <span style={{ color: "red" }}>Deleted!</span>
            ) : (
              "Editing:"
            )}{" "}
            {art?.[artIndex]?.title}
          </h4>
          <div
            style={{
              borderBottom: "1px solid white",
              marginBottom: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <p>Detail Image:</p>

            <EditForm
              fRef={detailUploadFormRef}
              iRef={detailUploadRef}
              fname="imgdetail.imgsrc"
              ftype="file"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.imgsrc}
              onSubmit={(ev) =>
                uploadImg(
                  ev,
                  art?.[artIndex]?.id,
                  detailUploadFormRef,
                  detailUploadRef
                )
              }
            />
            <EditForm
              fRef={detailColourFormRef}
              iRef={detailColourRef}
              fname="imgdetail.colour"
              ftype="text"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.colour}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailColourFormRef,
                  detailColourRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailFormatsFormRef}
              sRef={detailFormatsRef}
              fname="imgdetail.formats"
              iselect={true}
              detfull="detail"
              isize={2}
              ioptions={["avif", "webp", "png", "jpg", "gif", "svg"]}
              aIndexName={art?.[artIndex]?.imgdetail?.formats!}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailFormatsFormRef,
                  detailFormatsRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailHeightpxFormRef}
              iRef={detailHeightpxRef}
              fname="imgdetail.heightpx"
              ftype="text"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.heightpx}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailHeightpxFormRef,
                  detailHeightpxRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailImgaltFormRef}
              iRef={detailImgaltRef}
              fname="imgdetail.imgalt"
              ftype="text"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.imgalt}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailImgaltFormRef,
                  detailImgaltRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailImgqualityFormRef}
              sRef={detailImgqualityRef}
              fname="imgdetail.imgquality"
              detfull="detail"
              iselect={true}
              isize={1}
              ioptions={["max", "high", "mid", "low"]}
              aIndexName={art?.[artIndex]?.imgdetail.imgquality}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailImgqualityFormRef,
                  detailImgqualityRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailImgsrcFormRef}
              iRef={detailImgsrcRef}
              fname="imgdetail.imgsrc"
              ftype="text"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.imgsrc}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailImgsrcFormRef,
                  detailImgsrcRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailWidthpxFormRef}
              iRef={detailWidthpxRef}
              fname="imgdetail.widthpx"
              ftype="text"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.widthpx}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailWidthpxFormRef,
                  detailWidthpxRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={detailWidththumbFormRef}
              iRef={detailWidththumbRef}
              fname="imgdetail.widththumb"
              ftype="text"
              detfull="detail"
              aIndexName={art?.[artIndex]?.imgdetail?.widththumb}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  detailWidththumbFormRef,
                  detailWidththumbRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <p>Full Image:</p>
            <EditForm
              fRef={fullUploadFormRef}
              iRef={fullUploadRef}
              fname="imgfull.imgsrc"
              ftype="file"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.imgsrc}
              onSubmit={(ev) =>
                uploadImg(
                  ev,
                  art?.[artIndex]?.id,
                  fullUploadFormRef,
                  fullUploadRef
                )
              }
            />
            <EditForm
              fRef={fullColourFormRef}
              iRef={fullColourRef}
              fname="imgfull.colour"
              ftype="text"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.colour}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullColourFormRef,
                  fullColourRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullFormatsFormRef}
              sRef={fullFormatsRef}
              fname="imgfull.formats"
              iselect={true}
              detfull="full"
              isize={2}
              ioptions={["avif", "webp", "png", "jpg", "gif", "svg"]}
              aIndexName={
                art?.[artIndex]?.imgfull?.formats
                  ? art?.[artIndex]?.imgfull?.formats
                  : null
              }
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullFormatsFormRef,
                  fullFormatsRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullHeightpxFormRef}
              iRef={fullHeightpxRef}
              fname="imgfull.heightpx"
              ftype="text"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.heightpx}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullHeightpxFormRef,
                  fullHeightpxRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullImgaltFormRef}
              iRef={fullImgaltRef}
              fname="imgfull.imgalt"
              ftype="text"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.imgalt}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullImgaltFormRef,
                  fullImgaltRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullImgqualityFormRef}
              sRef={fullImgqualityRef}
              fname="imgfull.imgquality"
              detfull="full"
              iselect={true}
              isize={1}
              ioptions={["max", "high", "mid", "low"]}
              aIndexName={
                art?.[artIndex]?.imgfull?.imgquality!
                  ? art?.[artIndex]?.imgfull.imgquality
                  : null
              }
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullImgqualityFormRef,
                  fullImgqualityRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullImgsrcFormRef}
              iRef={fullImgsrcRef}
              fname="imgfull.imgsrc"
              ftype="text"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.imgsrc}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullImgsrcFormRef,
                  fullImgsrcRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullWidthpxFormRef}
              iRef={fullWidthpxRef}
              fname="imgfull.widthpx"
              ftype="text"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.widthpx}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullWidthpxFormRef,
                  fullWidthpxRef,
                  art?.[artIndex]?.title
                )
              }
            />
            <EditForm
              fRef={fullWidththumbFormRef}
              iRef={fullWidththumbRef}
              fname="imgfull.widththumb"
              ftype="text"
              detfull="full"
              aIndexName={art?.[artIndex]?.imgfull?.widththumb}
              onSubmit={(ev) =>
                runEdit(
                  ev,
                  art?.[artIndex]?.id,
                  fullWidththumbFormRef,
                  fullWidththumbRef,
                  art?.[artIndex]?.title
                )
              }
            />
          </div>
          <EditForm
            fRef={artistFormRef}
            iRef={artistRef}
            fname="artist"
            ftype="text"
            aIndexName={art?.[artIndex]?.artist}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                artistFormRef,
                artistRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={availableFormRef}
            iRef={availableRef}
            fname="available"
            ftype="checkbox"
            aIndexName={art?.[artIndex]?.available}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                availableFormRef,
                availableRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={categoryFormRef}
            sRef={categoryRef}
            fname="category"
            iselect={true}
            isize={1}
            ioptions={["Artwork", "Commissions"]}
            aIndexName={art?.[artIndex]?.category}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                categoryFormRef,
                categoryRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={heightFormRef}
            iRef={heightRef}
            fname="height"
            ftype="text"
            aIndexName={art?.[artIndex]?.height}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                heightFormRef,
                heightRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={homeimgFormRef}
            iRef={homeimgRef}
            fname="homeimg"
            ftype="checkbox"
            aIndexName={art?.[artIndex]?.homeimg}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                homeimgFormRef,
                homeimgRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={includeFormRef}
            iRef={includeRef}
            fname="include"
            ftype="checkbox"
            aIndexName={art?.[artIndex]?.include}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                includeFormRef,
                includeRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={textFormRef}
            tRef={textRef}
            fname="text"
            ftype="text"
            aIndexName={art?.[artIndex]?.text}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                textFormRef,
                textRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={titleFormRef}
            iRef={titleRef}
            fname="title"
            ftype="text"
            aIndexName={art?.[artIndex]?.title}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                titleFormRef,
                titleRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={keyidFormRef}
            iRef={keyidRef}
            fname="keyid"
            ftype="text"
            aIndexName={art?.[artIndex]?.keyid}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                keyidFormRef,
                keyidRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={orderFormRef}
            iRef={orderRef}
            fname="order"
            ftype="text"
            aIndexName={art?.[artIndex]?.order}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                orderFormRef,
                orderRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={lochFormRef}
            iRef={lochRef}
            fname="loch"
            ftype="text"
            aIndexName={art?.[artIndex]?.loch}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                lochFormRef,
                lochRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={mediumFormRef}
            sRef={mediumRef}
            fname="medium"
            iselect={true}
            isize={1}
            ioptions={[
              "Oil on Panel",
              "Oil on Linen",
              "Oil and Tempera on Paper",
              "Carbon Pencil and Tempera on Paper",
              "Watercolour on Paper",
              "Pencil on Paper",
            ]}
            aIndexName={art?.[artIndex]?.medium}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                mediumFormRef,
                mediumRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={metaFormRef}
            iRef={metaRef}
            fname="meta"
            ftype="text"
            aIndexName={art?.[artIndex]?.meta}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                metaFormRef,
                metaRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={priceFormRef}
            iRef={priceRef}
            fname="price"
            ftype="text"
            aIndexName={art?.[artIndex]?.price}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                priceFormRef,
                priceRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={slugFormRef}
            iRef={slugRef}
            fname="slug"
            ftype="text"
            aIndexName={art?.[artIndex]?.slug}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                slugFormRef,
                slugRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={subjectFormRef}
            sRef={subjectRef}
            fname="subject"
            iselect={true}
            isize={1}
            ioptions={["still life", "figure", "portrait"]}
            aIndexName={art?.[artIndex]?.subject}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                subjectFormRef,
                subjectRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={typeFormRef}
            sRef={typeRef}
            fname="type"
            iselect={true}
            isize={1}
            ioptions={["painting", "drawing"]}
            aIndexName={art?.[artIndex]?.type}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                typeFormRef,
                typeRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={widthFormRef}
            iRef={widthRef}
            fname="width"
            ftype="text"
            aIndexName={art?.[artIndex]?.width}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                widthFormRef,
                widthRef,
                art?.[artIndex]?.title
              )
            }
          />
          <EditForm
            fRef={yearFormRef}
            iRef={yearRef}
            fname="year"
            ftype="text"
            aIndexName={art?.[artIndex]?.year}
            onSubmit={(ev) =>
              runEdit(
                ev,
                art?.[artIndex]?.id,
                yearFormRef,
                yearRef,
                art?.[artIndex]?.title
              )
            }
          />
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>ID: {art?.[artIndex]?.id}</p>
            <button
              className="btn"
              onClick={() => delArtwork(art?.[artIndex]?.id)}
            >
              Delete
            </button>
          </div>
          <button className="btn" onClick={setEditArtClose}>
            Close
          </button>
        </div>
      ) : null}
      {showAddArt ? (
        <AddArtForm
          onSubmit={(ev) => {
            addArtwork(ev);
          }}
          fRef={addFormRef}
          iTRef={addTitleRef}
          iKRef={addKeyidRef}
          iORef={addOrderRef}
        />
      ) : null}
      {showArtList ? (
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {art?.map((artwork, i: number) => (
              <button
                key={i}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  artEditCard(i);
                }}
              >
                {artwork.title} {artwork.order}
              </button>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              borderBottom: "1px solid white",
              marginBottom: "1rem",
            }}
          >
            <button className="btn btnheading" onClick={startAddArt}>
              {showAddArt ? `Hide Add Art` : `Show Add Art`}
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EditArtworks;
