import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../../fbserve";

const HomeImgForm = () => {
  let dataRef: any;
  const homeImgStr = "pages/home/imgs";
  const homeImgRef = doc(db, homeImgStr, "img");

  // add Home Image Refs
  const homeUploadFormRef = useRef<HTMLFormElement>(null);
  const homeUploadRef = useRef<HTMLInputElement>(null);
  const homeColourFormRef = useRef<HTMLFormElement>(null);
  const homeColourRef = useRef<HTMLInputElement>(null);
  const homeFormatsFormRef = useRef<HTMLFormElement>(null);
  const homeFormatsRef = useRef<HTMLSelectElement>(null);
  const homeHeightpxFormRef = useRef<HTMLFormElement>(null);
  const homeHeightpxRef = useRef<HTMLInputElement>(null);
  const homeImgaltFormRef = useRef<HTMLFormElement>(null);
  const homeImgaltRef = useRef<HTMLInputElement>(null);
  const homeImgqualityFormRef = useRef<HTMLFormElement>(null);
  const homeImgqualityRef = useRef<HTMLSelectElement>(null);
  const homeImgsrcFormRef = useRef<HTMLFormElement>(null);
  const homeImgsrcRef = useRef<HTMLInputElement>(null);
  const homeOrderFormRef = useRef<HTMLFormElement>(null);
  const homeOrderRef = useRef<HTMLInputElement>(null);
  const homeWidthpxFormRef = useRef<HTMLFormElement>(null);
  const homeWidthpxRef = useRef<HTMLInputElement>(null);
  const homeWidththumbFormRef = useRef<HTMLFormElement>(null);
  const homeWidththumbRef = useRef<HTMLInputElement>(null);

  const [homeimg, setHomeImgData] = useState<any>([]);
  const [homeImg, setHomeImg] = useState(false);
  const [redEdited, setRedEdited] = useState(true);

  const getHomeImgData = async () => {
    const homeImgSnap = await getDoc(homeImgRef);
    if (homeImgSnap.exists()) {
      setHomeImgData({ ...homeImgSnap.data(), id: homeImgSnap.id });
    } else {
      // homeImgSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    getHomeImgData();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const storage = getStorage();
  let urlArr: Array<any> = [];
  const uploadImg = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.files;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (inptVal.length < 1 && inptVal.length > 1) {
      alert("Please Select Only One Image File");
      setRedEdited(false);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      return;
    } else if (inptVal.length === 1) {
      for (let i: number = 0; i < inptVal!.length; i++) {
        const artimagesRef = ref(storage, `art/${inptVal![i].name}`);
        await uploadBytes(artimagesRef, inptVal![i]);
        await getDownloadURL(artimagesRef)
          .then((url) => {
            urlArr.push(url);
          })
          .catch((error: any) => {
            console.log("Error Getting Img URLs!", error.message);
          });
      }
      dataRef = doc(db, dataStr, id);
      await updateDoc(dataRef, {
        [`${inptName}`]: urlArr[0].toString(),
      });
      setRedEdited(true);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      getHomeImgData();
    }
  };

  const runSectEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (
      inptName === "order" ||
      inptName === "heightpx" ||
      inptName === "widthpx" ||
      inptName === "widththumb"
    ) {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getHomeImgData();
  };

  return (
    <div>
      {homeImg === true ? (
        <div className="editdiv">
          <h4>Home Image Addition</h4>
          <EditForm
            fRef={homeUploadFormRef}
            iRef={homeUploadRef}
            fname="imgsrc"
            ftype="file"
            detfull=""
            aIndexName={homeimg?.imgsrc}
            onSubmit={(ev) =>
              uploadImg(
                ev,
                homeimg?.id,
                homeUploadFormRef,
                homeUploadRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeColourFormRef}
            iRef={homeColourRef}
            fname="colour"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.colour}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeColourFormRef,
                homeColourRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeFormatsFormRef}
            sRef={homeFormatsRef}
            fname="formats"
            iselect={true}
            detfull=""
            isize={2}
            ioptions={["avif", "webp", "png", "jpg", "gif", "svg"]}
            aIndexName={homeimg?.formats!}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeFormatsFormRef,
                homeFormatsRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeHeightpxFormRef}
            iRef={homeHeightpxRef}
            fname="heightpx"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.heightpx}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeHeightpxFormRef,
                homeHeightpxRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeImgaltFormRef}
            iRef={homeImgaltRef}
            fname="imgalt"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.imgalt}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeImgaltFormRef,
                homeImgaltRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeImgqualityFormRef}
            sRef={homeImgqualityRef}
            fname="imgquality"
            detfull=""
            iselect={true}
            isize={1}
            ioptions={["max", "high", "mid", "low"]}
            aIndexName={homeimg?.imgquality}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeImgqualityFormRef,
                homeImgqualityRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeImgsrcFormRef}
            iRef={homeImgsrcRef}
            fname="imgsrc"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.imgsrc}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeImgsrcFormRef,
                homeImgsrcRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeOrderFormRef}
            iRef={homeOrderRef}
            fname="order"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.order}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeOrderFormRef,
                homeOrderRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeWidthpxFormRef}
            iRef={homeWidthpxRef}
            fname="widthpx"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.widthpx}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeWidthpxFormRef,
                homeWidthpxRef,
                homeImgStr
              )
            }
          />
          <EditForm
            fRef={homeWidththumbFormRef}
            iRef={homeWidththumbRef}
            fname="widththumb"
            ftype="text"
            detfull=""
            aIndexName={homeimg?.widththumb}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                homeimg?.id,
                homeWidththumbFormRef,
                homeWidththumbRef,
                homeImgStr
              )
            }
          />
          <button className="btn" onClick={() => setHomeImg(false)}>
            Close Home Image
          </button>
        </div>
      ) : (
        <button className="btn" onClick={() => setHomeImg(true)}>
          Edit Home Image
        </button>
      )}
    </div>
  );
};

export default HomeImgForm;
