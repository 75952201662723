import { type FormEventHandler, type RefObject } from "react";

type AddFormProps = {
	fRef: RefObject<HTMLFormElement>;
	iTRef?: RefObject<HTMLInputElement>;
	iKRef?: RefObject<HTMLInputElement>;
	iORef?: RefObject<HTMLInputElement>;
	onSubmit: FormEventHandler;
};
const AddArtForm = ({ fRef, iTRef, iKRef, iORef, onSubmit }: AddFormProps) => (
	<form
		className=""
		ref={fRef}
		autoComplete="off"
		onSubmit={onSubmit}
		name="add"
	>
		<fieldset className="formfield">
			<label htmlFor="title">Title: </label>
			<input id="title" ref={iTRef} type="text" name="title" required />
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addkeyid">keyid: </label>
			<input id="addkeyid" ref={iKRef} type="text" name="keyid" required />
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addorder">order: </label>
			<input id="addorder" ref={iORef} type="text" name="order" required />
		</fieldset>
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				borderBottom: "1px solid white",
				marginBottom: "1rem",
			}}
		>
			<button type="submit" className="btn valuebtn">
				Add Artwork
			</button>
		</div>
	</form>
);

export default AddArtForm;
