import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import AddAwardsForm from "./add/AddAwardsForm";
import { db } from "../../fbserve";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";

const BioAwardsForm = () => {
  let dataRef: any;
  const awardStr = "pages/biography/awards";
  const awardsRef = query(collection(db, awardStr));
  const qawards = query(awardsRef, orderBy("order", "asc"));
  // add Technique Steps Refs
  const awardCategoryFormRef = useRef<HTMLFormElement>(null);
  const awardCategoryRef = useRef<HTMLInputElement>(null);
  const awardDatesFormRef = useRef<HTMLFormElement>(null);
  const awardDatesRef = useRef<HTMLInputElement>(null);
  const awardLocationFormRef = useRef<HTMLFormElement>(null);
  const awardLocationRef = useRef<HTMLInputElement>(null);
  const awardOrderFormRef = useRef<HTMLFormElement>(null);
  const awardOrderRef = useRef<HTMLInputElement>(null);
  const awardSectionFormRef = useRef<HTMLFormElement>(null);
  const awardSectionRef = useRef<HTMLInputElement>(null);
  const awardSubtextFormRef = useRef<HTMLFormElement>(null);
  const awardSubtextRef = useRef<HTMLInputElement>(null);
  const awardTextFormRef = useRef<HTMLFormElement>(null);
  const awardTextRef = useRef<HTMLInputElement>(null);
  const awardYearFormRef = useRef<HTMLFormElement>(null);
  const awardYearRef = useRef<HTMLInputElement>(null);
  const addAwardFormRef = useRef<HTMLFormElement>(null);
  const addAwardOrderRef = useRef<HTMLInputElement>(null);
  const addAwardTextRef = useRef<HTMLTextAreaElement>(null);

  const [awards, setSteps] = useState([] as Array<any> | null);
  const [bioAward, setBioAward] = useState(false);
  const [editAward, setEditAward] = useState(false);
  const [awardIndex, setAwardIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const getAwards = async () => {
    const awardSteps = [] as Array<any>;
    const querySnapshot = await getDocs(qawards);
    querySnapshot.forEach((doc: any) => {
      awardSteps?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setSteps(awardSteps);
  };

  useEffect(() => {
    getAwards();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const procEditAward = (i: number) => {
    const nextState = i;
    setEditAward(true);
    setAwardIndex(nextState);
  };

  const addTechStep = async (ev: any) => {
    ev.preventDefault();

    const iOrder = addAwardOrderRef.current?.value as string;
    await addDoc(collection(db, awardStr), {
      category: "bio",
      order: parseInt(iOrder),
      section: "Awards",
      subtext: "",
      text: addAwardTextRef.current?.value,
    })
      .then(() => {
        addAwardFormRef.current!.reset();
        getAwards();
      })
      .catch((error) => console.log(error.message));
  };

  const delPageSec = async (ev: any, id: string, deldir: string) => {
    ev.preventDefault();
    let confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${awards?.[awardIndex]?.text} Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, deldir, `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        setEditAward(false);
        getAwards();
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const runAwardEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (inptName === "order") {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getAwards();
  };

  return (
    <div>
      {bioAward === true ? (
        <div className="editdiv">
          {editAward ? (
            <div>
              <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
                {deleted ? (
                  <span style={{ color: "red" }}>Deleted!</span>
                ) : (
                  "Editing:"
                )}{" "}
                Biography Awards - {awards?.[awardIndex]?.text}
              </h4>
              <div>
                <EditForm
                  fRef={awardCategoryFormRef}
                  iRef={awardCategoryRef}
                  fname="category"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.category}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardCategoryFormRef,
                      awardCategoryRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardDatesFormRef}
                  iRef={awardDatesRef}
                  fname="dates"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.dates}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardDatesFormRef,
                      awardDatesRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardLocationFormRef}
                  iRef={awardLocationRef}
                  fname="location"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.location}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardLocationFormRef,
                      awardLocationRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardOrderFormRef}
                  iRef={awardOrderRef}
                  fname="order"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.order}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardOrderFormRef,
                      awardOrderRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardSectionFormRef}
                  iRef={awardSectionRef}
                  fname="section"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.section}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardSectionFormRef,
                      awardSectionRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardSubtextFormRef}
                  iRef={awardSubtextRef}
                  fname="subtext"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.subtext}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardSubtextFormRef,
                      awardSubtextRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardTextFormRef}
                  iRef={awardTextRef}
                  fname="text"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.text}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardTextFormRef,
                      awardTextRef,
                      awardStr
                    )
                  }
                />
                <EditForm
                  fRef={awardYearFormRef}
                  iRef={awardYearRef}
                  fname="year"
                  ftype="text"
                  detfull=""
                  aIndexName={awards?.[awardIndex]?.year}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      awards?.[awardIndex]?.id,
                      awardYearFormRef,
                      awardYearRef,
                      awardStr
                    )
                  }
                />
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <p>ID: {awards?.[awardIndex]?.id}</p>

                  <button
                    className="btn"
                    onClick={(ev) =>
                      delPageSec(ev, awards?.[awardIndex]?.id, awardStr)
                    }
                  >
                    Delete
                  </button>
                </div>
                <button className="btn" onClick={() => setEditAward(false)}>
                  Close
                </button>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <div style={{ width: "100%" }}>
              <h4>Add Awards:</h4>
            </div>
            {awards?.map((award: any, i: number) => (
              <button
                key={award.order}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  procEditAward(i);
                }}
              >
                {award.text} {award.order}
              </button>
            ))}
          </div>
          <div>
            <AddAwardsForm
              onSubmit={(ev) => {
                addTechStep(ev);
              }}
              fRef={addAwardFormRef}
              iTRef={addAwardTextRef}
              iORef={addAwardOrderRef}
            />
          </div>
          <button className="btn" onClick={() => setBioAward(false)}>
            Close Awards
          </button>
        </div>
      ) : (
        <button className="btn" onClick={() => setBioAward(true)}>
          Edit Awards
        </button>
      )}
    </div>
  );
};

export default BioAwardsForm;
