import { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  inMemoryPersistence,
} from "firebase/auth";
import { auth } from "../../fbserve";
import EditStart from "./EditStart";

// ***********SignInForm Component********
const SignInForm = () => {
  const [signedIn, setSignedIn] = useState(false);
  const [userName, setUserName] = useState("");
  let name: string;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid: string = user.uid;
        // console.log("uid", uid);
        setSignedIn(true);
      } else {
        // User is signed out
        setSignedIn(false);
        console.log("user is logged out");
      }
    });
  }, []);

  const subSignIn = async (ev: any) => {
    ev.preventDefault();
    const signinForm = ev.target.parentElement! as HTMLFormElement;
    const formData = new FormData(signinForm);
    const email = formData.get("email")?.toString();
    const password = formData.get("password")?.toString();
    if (!email || !password) {
      return;
    }
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        name = userCredential.user!.displayName!.toString();
      })
      .then(() => {
        auth.setPersistence(inMemoryPersistence);
        setUserName(name);
        setSignedIn(true);
      })
      .catch((error) => {
        alert("Incorrect Email or Password. Please Try Again.");
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const signOutUser = async (ev: any) => {
    ev.preventDefault();
    signOut(auth)
      .then(() => {
        setUserName("");
        setSignedIn(false);
      })
      .catch((error) => {
        console.log("Logging Out Error:", error.message, "Code:", error.code);
      });
  };

  return (
    <div>
      {signedIn === true ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 style={{ margin: "0" }}>{userName} you are logged in!</h3>
            <button
              onClick={(ev) => signOutUser(ev)}
              className="btn valuebtn"
              id="logout-btn"
            >
              Log Out
            </button>
          </div>
          <EditStart />
        </>
      ) : (
        <h3 style={{ margin: "0" }}>Sign In:</h3>
      )}
      {signedIn === false ? (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.36rem",
            alignItems: "baseline",
          }}
          method="POST"
        >
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" autoComplete="email" />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            autoComplete="on"
          />
          <button onClick={(ev) => subSignIn(ev)} className="btn" type="submit">
            Login
          </button>
        </form>
      ) : null}
    </div>
  );
};

export default SignInForm;
