// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAAeBXNxnixZNIwB4t-2ZF_tIaBJNwxYFw",
  authDomain: "jha-web.firebaseapp.com",
  projectId: "jha-web",
  storageBucket: "jha-web.appspot.com",
  messagingSenderId: "102539328863",
  appId: "1:102539328863:web:77b4fe0d188f4f33b60aea",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export default getFirestore();
