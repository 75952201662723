import PageEditForms from "../page/PageEditForms";
import EditArtworks from "../art/EditArtworks";
import Trigger from "../start/Trigger";

const EditStart = () => {
  return (
    <div>
      <EditArtworks />
      <PageEditForms />
      <Trigger />
    </div>
  );
};

export default EditStart;
