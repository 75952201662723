import { type FormEventHandler, type RefObject } from "react";

type AddCommFormProps = {
	fRef: RefObject<HTMLFormElement>;
	iORef?: RefObject<HTMLInputElement>;
	iLRef?: RefObject<HTMLInputElement>;
	iGRef?: RefObject<HTMLInputElement>;
	onSubmit: FormEventHandler;
};
const AddRepCityForm = ({
	fRef,
	iORef,
	iLRef,
	iGRef,
	onSubmit,
}: AddCommFormProps) => (
	<form
		className=""
		ref={fRef}
		autoComplete="off"
		onSubmit={onSubmit}
		name="add"
	>
		<fieldset className="formfield">
			<label htmlFor="addrepcityorder">order: </label>
			<input
				id="addrepcityorder"
				ref={iORef}
				type="text"
				name="order"
				required
			/>
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addrepcitylocation">location: </label>
			<input
				id="addrepcitylocation"
				ref={iLRef}
				type="text"
				name="location"
				required
			/>
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addrepcitygallery">gallery: </label>
			<input
				id="addrepcitygallery"
				ref={iGRef}
				type="text"
				name="gallery"
				required
			/>
		</fieldset>

		<button type="submit" className="btn valuebtn">
			Add City
		</button>
	</form>
);

export default AddRepCityForm;
