import React from "react";
import ReactDOM from "react-dom/client";
import "./boilderplate.css";
import App from "./App";
import "@fontsource/albert-sans";
import "@fontsource/albert-sans/300.css"; //  Weight light 300
import "@fontsource/albert-sans/400.css"; //  Weight light 400
import "@fontsource/albert-sans/300-italic.css"; //  Weight thin italic 300
// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
