import { type FormEventHandler, type RefObject } from "react";

type AddCommFormProps = {
	fRef: RefObject<HTMLFormElement>;
	iORef?: RefObject<HTMLInputElement>;
	iHRef?: RefObject<HTMLTextAreaElement>;
	iTRef?: RefObject<HTMLInputElement>;
	onSubmit: FormEventHandler;
};
const AddCommProcForm = ({
	fRef,
	iORef,
	iHRef,
	iTRef,
	onSubmit,
}: AddCommFormProps) => (
	<form
		className=""
		ref={fRef}
		autoComplete="off"
		onSubmit={onSubmit}
		name="add"
	>
		<fieldset className="formfield">
			<label htmlFor="addcommprocorder">order: </label>
			<input
				id="addcommprocorder"
				ref={iORef}
				type="text"
				name="order"
				required
			/>
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addcommproText">text: </label>
			<textarea
				style={{ marginLeft: "0.5rem" }}
				id="addcommproText"
				ref={iHRef}
				name="text"
				rows={5}
				required
			/>
		</fieldset>
		<fieldset className="formfield">
			<label htmlFor="addcommproctitle">Title: </label>
			<input
				id="addcommproctitle"
				ref={iTRef}
				type="text"
				name="title"
				required
			/>
		</fieldset>

		<button type="submit" className="btn valuebtn">
			Add Process
		</button>
	</form>
);

export default AddCommProcForm;
