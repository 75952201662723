import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../../fbserve";

const BioImgForm = () => {
  let dataRef: any;
  const bioImgStr = "pages/biography/imgs";
  const bioImgRef = doc(db, bioImgStr, "img");

  // add Home Image Refs
  const bioUploadFormRef = useRef<HTMLFormElement>(null);
  const bioUploadRef = useRef<HTMLInputElement>(null);
  const bioColourFormRef = useRef<HTMLFormElement>(null);
  const bioColourRef = useRef<HTMLInputElement>(null);
  const bioFormatsFormRef = useRef<HTMLFormElement>(null);
  const bioFormatsRef = useRef<HTMLSelectElement>(null);
  const bioHeightpxFormRef = useRef<HTMLFormElement>(null);
  const bioHeightpxRef = useRef<HTMLInputElement>(null);
  const bioImgaltFormRef = useRef<HTMLFormElement>(null);
  const bioImgaltRef = useRef<HTMLInputElement>(null);
  const bioImgqualityFormRef = useRef<HTMLFormElement>(null);
  const bioImgqualityRef = useRef<HTMLSelectElement>(null);
  const bioImgsrcFormRef = useRef<HTMLFormElement>(null);
  const bioImgsrcRef = useRef<HTMLInputElement>(null);
  const bioOrderFormRef = useRef<HTMLFormElement>(null);
  const bioOrderRef = useRef<HTMLInputElement>(null);
  const bioWidthpxFormRef = useRef<HTMLFormElement>(null);
  const bioWidthpxRef = useRef<HTMLInputElement>(null);
  const bioWidththumbFormRef = useRef<HTMLFormElement>(null);
  const bioWidththumbRef = useRef<HTMLInputElement>(null);

  const [bioimg, setBioImgData] = useState<any>([]);
  const [bioImg, setBioImg] = useState(false);
  const [redEdited, setRedEdited] = useState(true);

  const getBioImgData = async () => {
    const bioImgSnap = await getDoc(bioImgRef);
    if (bioImgSnap.exists()) {
      setBioImgData({ ...bioImgSnap.data(), id: bioImgSnap.id });
    } else {
      // bioImgSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    getBioImgData();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const storage = getStorage();
  let urlArr: Array<any> = [];
  const uploadImg = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.files;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (inptVal.length < 1 && inptVal.length > 1) {
      alert("Please Select Only One Image File");
      setRedEdited(false);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      return;
    } else if (inptVal.length === 1) {
      for (let i: number = 0; i < inptVal!.length; i++) {
        const artimagesRef = ref(storage, `art/${inptVal![i].name}`);
        await uploadBytes(artimagesRef, inptVal![i]);
        await getDownloadURL(artimagesRef)
          .then((url) => {
            urlArr.push(url);
          })
          .catch((error: any) => {
            console.log("Error Getting Img URLs!", error.message);
          });
      }
      dataRef = doc(db, dataStr, id);
      await updateDoc(dataRef, {
        [`${inptName}`]: urlArr[0].toString(),
      });
      setRedEdited(true);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();

      getBioImgData();
    }
  };

  const runSectEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (
      inptName === "order" ||
      inptName === "heightpx" ||
      inptName === "widthpx" ||
      inptName === "widththumb"
    ) {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getBioImgData();
  };

  return (
    <div>
      {bioImg === true ? (
        <div className="editdiv">
          <h4>Biography Image Addition</h4>

          <EditForm
            fRef={bioUploadFormRef}
            iRef={bioUploadRef}
            fname="imgsrc"
            ftype="file"
            detfull=""
            aIndexName={bioimg?.imgsrc}
            onSubmit={(ev) =>
              uploadImg(
                ev,
                bioimg?.id,
                bioUploadFormRef,
                bioUploadRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioColourFormRef}
            iRef={bioColourRef}
            fname="colour"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.colour}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioColourFormRef,
                bioColourRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioFormatsFormRef}
            sRef={bioFormatsRef}
            fname="formats"
            iselect={true}
            detfull=""
            isize={2}
            ioptions={["avif", "webp", "png", "jpg", "gif", "svg"]}
            aIndexName={bioimg?.formats!}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioFormatsFormRef,
                bioFormatsRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioHeightpxFormRef}
            iRef={bioHeightpxRef}
            fname="heightpx"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.heightpx}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioHeightpxFormRef,
                bioHeightpxRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioImgaltFormRef}
            iRef={bioImgaltRef}
            fname="imgalt"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.imgalt}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioImgaltFormRef,
                bioImgaltRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioImgqualityFormRef}
            sRef={bioImgqualityRef}
            fname="imgquality"
            detfull=""
            iselect={true}
            isize={1}
            ioptions={["max", "high", "mid", "low"]}
            aIndexName={bioimg?.imgquality}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioImgqualityFormRef,
                bioImgqualityRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioImgsrcFormRef}
            iRef={bioImgsrcRef}
            fname="imgsrc"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.imgsrc}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioImgsrcFormRef,
                bioImgsrcRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioOrderFormRef}
            iRef={bioOrderRef}
            fname="order"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.order}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioOrderFormRef,
                bioOrderRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioWidthpxFormRef}
            iRef={bioWidthpxRef}
            fname="widthpx"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.widthpx}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioWidthpxFormRef,
                bioWidthpxRef,
                bioImgStr
              )
            }
          />
          <EditForm
            fRef={bioWidththumbFormRef}
            iRef={bioWidththumbRef}
            fname="widththumb"
            ftype="text"
            detfull=""
            aIndexName={bioimg?.widththumb}
            onSubmit={(ev) =>
              runSectEdit(
                ev,
                bioimg?.id,
                bioWidththumbFormRef,
                bioWidththumbRef,
                bioImgStr
              )
            }
          />
          <button className="btn" onClick={() => setBioImg(false)}>
            Close Bio Image
          </button>
        </div>
      ) : (
        <button className="btn" onClick={() => setBioImg(true)}>
          Edit Bio Image
        </button>
      )}
    </div>
  );
};

export default BioImgForm;
