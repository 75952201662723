import { type FormEventHandler, type RefObject } from "react";

type EditFormProps = {
	fRef: RefObject<HTMLFormElement>;
	iRef?: RefObject<HTMLInputElement>;
	sRef?: RefObject<HTMLSelectElement>;
	tRef?: RefObject<HTMLTextAreaElement>;
	fname: string;
	ftype?: string;
	detfull?: string;
	aIndexName: string;
	iselect?: boolean | null;

	ioptions?: string[] | null;
	isize?: number;
	onSubmit: FormEventHandler;
};

const EditForm = ({
	fRef,
	iRef,
	sRef,
	tRef,
	fname,
	ftype,
	detfull,
	aIndexName,
	iselect,

	ioptions,
	isize,
	onSubmit,
}: EditFormProps) => (
	<form
		className="editform"
		ref={fRef}
		autoComplete="off"
		onSubmit={onSubmit}
		name={`${fname}`}
	>
		{ftype === "checkbox" && !iselect ? (
			<label
				htmlFor={`${detfull === undefined ? "" : `${detfull}`}${fname}${aIndexName}`}
			>
				<span style={{ color: `var(--rust)` }}>{fname}: </span>
				{aIndexName ? "true" : "false"}
			</label>
		) : null}
		{ftype === "text" && !iselect ? (
			<label
				htmlFor={`${detfull === undefined ? "" : `${detfull}`}${fname}${aIndexName}`}
			>
				<span style={{ color: `var(--rust)` }}>{fname}: </span>
				{aIndexName}{" "}
			</label>
		) : null}
		{iselect === true ? (
			<>
				<label
					htmlFor={`${detfull === undefined ? "" : `${detfull}`}${fname}${aIndexName}`}
				>
					<span style={{ color: `var(--rust)` }}>{fname}: </span>
					{aIndexName ? aIndexName.toString() : "null"}{" "}
				</label>
				<select
					id={`${detfull === undefined ? "" : `${detfull}`}${fname}${aIndexName}`}
					ref={sRef}
					name={`${fname}`}
					size={isize}
					multiple
				>
					{ioptions?.map((opt, index) => (
						<option key={index} value={opt}>
							{opt}
						</option>
					))}
				</select>
			</>
		) : null}
		{iselect === undefined && fname !== "text" ? (
			<input
				id={`${detfull === undefined ? "" : `${detfull}`}${fname}${aIndexName}`}
				ref={iRef}
				type={ftype}
				name={`${fname}`}
			/>
		) : null}
		{iselect === undefined && fname === "text" ? (
			<textarea
				style={{ marginLeft: "0.5rem" }}
				id={`${detfull === undefined ? "" : `${detfull}`}${fname}${aIndexName}`}
				ref={tRef}
				rows={5}
				name={`${fname}`}
			/>
		) : null}
		<button type="submit" className="valuebtn">
			Update
		</button>
	</form>
);

export default EditForm;
