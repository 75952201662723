import { useRef, useState, useEffect } from "react";
import { db } from "../../fbserve";
import {
  collection,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { slugify } from "../../utilities/slugify";
import EditForm from "../template/EditForm";
import AddPageForm from "../page/add/AddPageForm";
import HomeImgForm from "./HomeImgForm";
import StepsForm from "./StepsForm";
import CitiesForm from "./CitiesForm";
import ProcessForm from "./ProsessForm";
import BioBornForm from "./BioBornForm";
import BioEduForm from "./BioEduForm";
import BioExhibitForm from "./BioExhibitForm";
import BioImgForm from "./BioImgForm";
import BioTeachForm from "./BioTeachForm";
import BioAwardsForm from "./BioAwardsForm";
import BioPublicationsForm from "./BioPublicationsForm";

const PageEditForms = () => {
  let dataRef: any;
  const pagesRef = collection(db, "pages");
  const qp = query(pagesRef, orderBy("order", "asc"));

  // Refs for Forms
  const pcategoryFormRef = useRef<HTMLFormElement>(null);
  const pcategoryRef = useRef<HTMLInputElement>(null);
  const pheadingFormRef = useRef<HTMLFormElement>(null);
  const pheadingRef = useRef<HTMLInputElement>(null);
  const pincludeFormRef = useRef<HTMLFormElement>(null);
  const pincludeRef = useRef<HTMLInputElement>(null);
  const pintroFormRef = useRef<HTMLFormElement>(null);
  const pintroRef = useRef<HTMLInputElement>(null);
  const pmetaFormRef = useRef<HTMLFormElement>(null);
  const pmetaRef = useRef<HTMLInputElement>(null);
  const porderFormRef = useRef<HTMLFormElement>(null);
  const porderRef = useRef<HTMLInputElement>(null);
  const ppagelinksFormRef = useRef<HTMLFormElement>(null);
  const ppagelinksRef = useRef<HTMLSelectElement>(null);
  const pslugFormRef = useRef<HTMLFormElement>(null);
  const pslugRef = useRef<HTMLInputElement>(null);
  const ptextFormRef = useRef<HTMLFormElement>(null);
  const ptextRef = useRef<HTMLTextAreaElement>(null);
  const ptitleFormRef = useRef<HTMLFormElement>(null);
  const ptitleRef = useRef<HTMLInputElement>(null);
  // add Page Form Refs
  const addPgFormRef = useRef<HTMLFormElement>(null);
  const addPgTitleRef = useRef<HTMLInputElement>(null);
  const addPgHeadingRef = useRef<HTMLInputElement>(null);
  const addOrderRef = useRef<HTMLInputElement>(null);

  const [pages, setPages] = useState([] as Array<any> | null);
  const [editPage, setEditPage] = useState(false);
  const [pgIndex, setPgIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [showPageList, setShowPageList] = useState(false);
  const [showAddPage, setShowAddPage] = useState(false);

  const getPages = async () => {
    const sitepages = [] as Array<any>;
    const querySnapshot = await getDocs(qp);
    querySnapshot.forEach((doc: any) => {
      sitepages?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setPages(sitepages);
  };

  useEffect(() => {
    getPages();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const setEditPageClose = () => {
    setEditPage(false);
    setRedEdited(false);
  };

  const pageEditCard = (i: number) => {
    const nextState = i;
    setEditPage(true);
    setShowAddPage(false);
    setPgIndex(nextState);
  };

  const startAddPage = () => {
    setShowAddPage((prev) => !prev);
  };

  const startPageList = () => {
    setShowPageList((prev) => !prev);
    setShowAddPage(false);
  };

  const addPage = async (ev: any) => {
    ev.preventDefault();
    const pgId: any = addPgTitleRef.current?.value.toLowerCase();
    const iOrder = addOrderRef.current?.value as string;
    await setDoc(doc(db, "pages", pgId), {
      category: "page",
      title: addPgTitleRef.current?.value,
      include: false,
      heading: addPgHeadingRef.current?.value,
      order: parseInt(iOrder),
      slug: slugify(addPgTitleRef!.current!.value.toString()),
    })
      .then(() => {
        addPgFormRef.current!.reset();
        getPages();
      })
      .catch((error) => console.log(error.message));
  };

  const delPage = async (ev: any, id: string) => {
    ev.preventDefault();
    let confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${pages?.[pgIndex]?.title} Artwork Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, "pages", `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        getPages();
        pageEditCard(0);
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const runEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    iTitle: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    let inptName = refInpt.current?.name;
    let inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (
      inptName === "order" ||
      inptName.includes("heightpx") ||
      inptName.includes("widthpx") ||
      inptName.includes("widththumb")
    ) {
      inptVal = parseInt(inptVal);
    }
    if (
      inptName === "available" ||
      inptName === "include" ||
      inptName === "homeimg"
    ) {
      inptVal = refInpt.current?.checked ? true : false;
    }
    if (inptName.includes("formats") || inptName.includes("pagelinks")) {
      let opts = inptForm?.children[1]?.selectedOptions!;
      let catOptions = [];
      for (let option of opts) {
        if (option.selected) {
          if (option.value !== "null") {
            catOptions.push(option.value);
          }
        }
      }

      inptVal = catOptions;
    }
    if (inptName === "artist") {
      inptVal = "John Hansen";
    }
    if (inptName === "loch") {
      inptVal = "https://lochgallery.com/artist/john-hansen";
    }
    if (inptName === "slug") {
      inptVal = slugify(iTitle);
    }
    dataRef = doc(db, "pages", id);
    if (inptName === "title") {
      getPages();
      await updateDoc(dataRef, { slug: slugify(inptVal) });
      setRedEdited(true);
    }
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    refForm.current.className = `${redEdited ? "editform red" : "editform"}`;
    refForm.current!.reset();
    getPages();
  };

  return (
    <div>
      {editPage ? (
        <div className="editdiv">
          <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
            {deleted ? (
              <span style={{ color: "red" }}>Deleted!</span>
            ) : (
              "Editing:"
            )}{" "}
            {pages?.[pgIndex]?.title}
          </h4>
          <EditForm
            fRef={pcategoryFormRef}
            iRef={pcategoryRef}
            fname="category"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.category}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                pcategoryFormRef,
                pcategoryRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={pheadingFormRef}
            iRef={pheadingRef}
            fname="heading"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.heading}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                pheadingFormRef,
                pheadingRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={pincludeFormRef}
            iRef={pincludeRef}
            fname="include"
            ftype="checkbox"
            aIndexName={pages?.[pgIndex]?.include}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                pincludeFormRef,
                pincludeRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={pintroFormRef}
            iRef={pintroRef}
            fname="intro"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.intro}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                pintroFormRef,
                pintroRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={pmetaFormRef}
            iRef={pmetaRef}
            fname="meta"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.meta}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                pmetaFormRef,
                pmetaRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={porderFormRef}
            iRef={porderRef}
            fname="order"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.order}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                porderFormRef,
                porderRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={ppagelinksFormRef}
            sRef={ppagelinksRef}
            fname="pagelinks"
            iselect={true}
            isize={2}
            ioptions={["artwork", "commissions", "null"]}
            ftype="text"
            aIndexName={pages?.[pgIndex]?.pagelinks}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                ppagelinksFormRef,
                ppagelinksRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={pslugFormRef}
            iRef={pslugRef}
            fname="slug"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.slug}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                pslugFormRef,
                pslugRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={ptextFormRef}
            tRef={ptextRef}
            fname="text"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.text}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                ptextFormRef,
                ptextRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <EditForm
            fRef={ptitleFormRef}
            iRef={ptitleRef}
            fname="title"
            ftype="text"
            aIndexName={pages?.[pgIndex]?.title}
            onSubmit={(ev) =>
              runEdit(
                ev,
                pages?.[pgIndex]?.id,
                ptitleFormRef,
                ptitleRef,
                pages?.[pgIndex]?.title
              )
            }
          />
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>ID: {pages?.[pgIndex]?.id}</p>
            <button
              className="btn"
              onClick={(ev) => delPage(ev, pages?.[pgIndex]?.id)}
            >
              Delete
            </button>
          </div>
          <button className="btn" onClick={setEditPageClose}>
            Close
          </button>
        </div>
      ) : null}
      {editPage && pages?.[pgIndex]?.title === "Home" ? <HomeImgForm /> : null}
      {editPage && pages?.[pgIndex]?.title === "Technique" ? (
        <StepsForm />
      ) : null}
      {editPage && pages?.[pgIndex]?.title === "Representation" ? (
        <CitiesForm />
      ) : null}
      {editPage && pages?.[pgIndex]?.title === "Commissions" ? (
        <ProcessForm />
      ) : null}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioAwardsForm />
        ) : null}
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioBornForm />
        ) : null}
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioEduForm />
        ) : null}
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioExhibitForm />
        ) : null}
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioImgForm />
        ) : null}
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioPublicationsForm />
        ) : null}
        {editPage && pages?.[pgIndex]?.title === "Biography" ? (
          <BioTeachForm />
        ) : null}
      </div>
      {showAddPage ? (
        <AddPageForm
          onSubmit={(ev) => {
            addPage(ev);
          }}
          fRef={addPgFormRef}
          iTRef={addPgTitleRef}
          iHRef={addPgHeadingRef}
          iORef={addOrderRef}
        />
      ) : null}
      <button className="btn btnheading" onClick={startPageList}>
        {showPageList ? `Hide Pages` : `Show Pages`}
      </button>
      {showPageList ? (
        <div>
          <div
            style={{
              borderTop: "1px solid white",
              paddingTop: "1rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {pages?.map((page: any, i: number) => (
              <button
                key={i}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  pageEditCard(i);
                }}
              >
                {page.title} {page.order}
              </button>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              borderBottom: "1px solid white",
              marginBottom: "1rem",
            }}
          >
            <button className="btn btnheading" onClick={startAddPage}>
              {showAddPage ? `Hide Add Pages` : `Add Pages`}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PageEditForms;
